import { createApp } from 'vue'

import { GtmPlugin } from '@/plugins/gtm'
import { useVueAxios } from '@/shared/js/axios'
import { useCj } from '@/shared/js/cj'

import CollectionSlider from '../shared/components/CollectionSlider.vue'
import LayoutMixin from '../shared/mixins/Layout'

import HomeBanners from './components/HomeBanners.vue'
import BuilderPreview from './components/BuilderPreview.vue'
import LatestBuilderPresets from './components/LatestBuilderPresets.vue'
import store from './store'

const app = createApp({
  components: {
    CollectionSlider,
    HomeBanners,
    BuilderPreview,
    LatestBuilderPresets,
  },
  mixins: [LayoutMixin],
})

app.use(store)
app.use(GtmPlugin)
useVueAxios(app, store)
useCj(app, store)

app.mount('#vbApp')
