<script setup lang="ts">
import { onBeforeUnmount, ref } from 'vue'

defineProps({
  presets: {
    type: Array,
    default: () => [],
  },
})

const currentPreset = ref(null)
const loading = ref(false)

const confirmDesign = () => {
  if (currentPreset.value?.href) {
    loading.value = true
    location.href = currentPreset.value.href
  }
}

onBeforeUnmount(() => {
  loading.value = false
})
</script>

<template>
  <div
    class="uk-position-relative uk-visible-toggle uk-margin-medium-top"
    style="overflow: visible"
    tabindex="-1"
    data-uk-slider="autoplay: true; autoplay-interval: 2000; center: true"
  >
    <ul
      class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@m uk-child-width-1-6@l uk-grid-small uk-margin-bottom"
      data-uk-grid
    >
      <li v-for="preset in presets" :key="preset.productInstanceId" style="max-width: 90%">
        <a
          class="uk-display-block vb-hover-scale vb-rounded uk-position-relative"
          style="aspect-ratio: 1 / 1; border: 1px solid #ececec"
          data-uk-toggle="#designed-product-quick"
          @click="currentPreset = preset"
        >
          <img
            class="uk-width-1-1 uk-height-1-1 vb-hover-scale__inner"
            style="object-fit: cover"
            :data-src="preset.snapshots[0]"
            data-uk-img
            :alt="preset.title"
          />

          <span class="uk-overlay uk-overlay-default uk-position-center uk-height-1-1" style="width: 100%; opacity: 0">
            <span class="uk-position-center" data-uk-spinner></span>
          </span>
        </a>
      </li>
    </ul>
    <button
      class="uk-icon-button uk-border-circle uk-position-center-left uk-margin-small-left"
      style="background-color: white"
      data-uk-icon="chevron-left"
      data-uk-slider-item="previous"
      type="button"
    />
    <button
      class="uk-icon-button uk-border-circle uk-position-center-right uk-margin-small-right"
      style="background-color: white"
      data-uk-icon="chevron-right"
      data-uk-slider-item="next"
      type="button"
    />
  </div>

  <div id="designed-product-quick" data-uk-modal>
    <div class="uk-modal-dialog vb-rounded-lg">
      <button class="uk-modal-close-default" type="button" data-uk-close></button>
      <div class="uk-modal-body">
        <template v-if="loading">
          <!--LOADING OVERLAY-->
          <div class="uk-overlay-default vb-overlay-cart uk-position-cover vb-rounded-lg" />
          <div class="uk-position-center vb-spinner-cart-update" data-uk-spinner />
          <!--end of LOADING OVERLAY-->
        </template>
        <div class="uk-position-relative uk-visible-toggle uk-margin-bottom" tabindex="-1" data-uk-slider>
          <div class="uk-slider-items uk-child-width-1-1 uk-grid-small" data-uk-grid>
            <div v-for="snapshot in currentPreset?.snapshots" :key="snapshot">
              <span
                class="vb-rounded uk-overflow-hidden uk-display-block"
                href="assets/img/brand/placeholder-light.svg"
              >
                <img style="aspect-ratio: 1/1; object-fit: cover" :src="snapshot" :alt="snapshot" />
              </span>
            </div>
          </div>

          <a
            class="uk-position-center-left uk-position-small uk-light"
            href
            data-uk-slidenav-previous
            data-uk-slider-item="previous"
          />
          <a
            class="uk-position-center-right uk-position-small uk-light"
            href
            data-uk-slidenav-next
            data-uk-slider-item="next"
          />
          <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin-top" style="color: black"></ul>
        </div>
        <div class="uk-flex uk-flex-center">
          <button
            v-if="currentPreset?.href"
            class="uk-button uk-flex-inline uk-margin-auto-left uk-margin-auto-right uk-button-secondary uk-text-bold"
            style="border-radius: 48px"
            @click="confirmDesign"
          >
            Modify
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
