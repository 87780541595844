<script setup>
import { computed } from 'vue'

import logoShortImg from '@/web/img/brand/logo-short.svg'
import logoShortDarkImg from '@/web/img/brand/logo-short--dark.svg'
import logoNew from '@/web/img/brand/new.svg'
import logoHot from '@/web/img/brand/hot.svg'
import logoSale from '@/web/img/brand/sale.svg'

const props = defineProps({
  banner: {
    type: Object,
    default: () => {},
  },
  background: {
    type: String,
    default: 'light',
  },
  cssClass: {
    type: String,
    default: 'uk-position-top-right uk-width-1-6@m uk-width-1-4 vb-product-label',
  },
})

const is_stars = computed(() => props.banner.marked_as_stars)

const source = computed(() => {
  if (props.banner.marked_as_hot) {
    return logoHot
  }

  if (props.banner.marked_as_new) {
    return logoNew
  }

  if (props.banner.marked_as_stars) {
    return props.background === 'dark' ? logoShortImg : logoShortDarkImg
  }

  if (props.banner.marked_as_sale) {
    return logoSale
  }

  return null
})

const alt = computed(() => {
  if (props.banner.marked_as_hot) {
    return 'Hot'
  }

  if (props.banner.marked_as_new) {
    return 'New'
  }

  if (props.banner.marked_as_stars) {
    return 'Stars'
  }

  if (props.banner.marked_as_sale) {
    return 'Varsity Base'
  }

  return null
})

const isLabeled = computed(() => {
  return (
    props.banner.marked_as_hot ||
    props.banner.marked_as_new ||
    props.banner.marked_as_stars ||
    props.banner.marked_as_sale
  )
})
</script>

<template>
  <div>
    <img
      v-if="isLabeled"
      :class="cssClass"
      :style="is_stars ? 'height: 24px; margin: 32px' : 'max-width: 60px; margin: 16px; transform: rotate(-30deg)'"
      :data-src="source"
      data-uk-img
      :alt="alt"
    />
  </div>
</template>
